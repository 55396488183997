(function($) {
	'use strict';

	var init = function() {
		formLabels();
		toggleSearch();
    if($('body').hasClass('log')) {
        nbProdunctPanier();
      }
    toggleFilter();
		displayFilters();
		hideFilters();
    manageProductCarousel();
    manageProductThumbnails();
	};

	var formLabels = function() {
		$('.form-group input:focus').parent().prev('.control-label').addClass('focused');
		$('.form-group input').each(function() {
			var label = $(this).parent().prev('.control-label');
      if( $(this).val() != '' ) {
        label.addClass('focused');
			}
		});


    $('.form-group input').focus(function() {
      var label = $(this).parent().prev('.control-label');
      label.addClass('focused');
    });

    $('#notes').focus(function() {
      var label = $(this).parent().prev('.control-label');
      label.addClass('focused');
    });

    $('.form-group input').focusout(function() {
      var label = $(this).parent().prev('.control-label');
      if( $(this).val() != '' ) {
        label.addClass('focused');
      } else if( label.hasClass('focused') ) {
        label.removeClass('focused');
      }
    });

    $('#notes').focusout(function() {
      var label = $(this).parent().prev('.control-label');
      if( $(this).val() != '' ) {
        label.addClass('focused');
      } else if( label.hasClass('focused') ) {
        label.removeClass('focused');
      }
    });
	};

	var toggleSearch = function() {
		$('#show-search').click(function() {
			$('#search').toggleClass('active');
		});
	}


  var toggleFilter = function() {

		$('.filter__listfamilly .has-children > a, .filter__listfamilly .has-children > strong').click(function(e) {
            e.preventDefault();
			$(this).parent('.has-children').toggleClass('active');
			$(this).siblings('ul').toggleClass('active');
		});
	}

	var displayFilters = function() { // Those are the products listing filters
		$('#display-filters').click(function() {
			$('#thefilters').addClass('displayed');
			$(this).addClass('hidden');
		});
	}

	var hideFilters = function() { // Those are the products listing filters
		$('#hide-filters').click(function() {
			$('#thefilters').removeClass('displayed');
			$('#display-filters').removeClass('hidden');
		});
	}

  var manageProductCarousel = function() {
		if( $('.product-images-carousel').length ) {
			$('.product-images-carousel').owlCarousel({
				items: 1,
				loop: false,
				autoplay: true,
				autoplayTimeout: 5000,
				smartSpeed: 1000,
				nav: false,
				mouseDrag: true,
				dots: false,
				autoWidth: false,
			});
		}
	};

    var manageProductThumbnails = function() {

		$('.product-thumbnails-wrapper').click(function(event) {
			var owl = $('.product-images-carousel.owl-carousel');
			owl.trigger('to.owl.carousel', [$(this).index(), 0]);
		});

	};



	$(document).ready(function() {
		init();
	});

})(jQuery);

if($('body').hasClass('log')) {
  var nbProdunctPanier = function(){

      $.ajax({
          url: 'http://abf35.ci/api/nb_product_panier',
          success: function(data){

              if(data >= 0){
                  $('#panier-nb-product').text(data);
              }

          },
          error: function(data){
              console.log(data);
          }
      });

  }
}
